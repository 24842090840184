<template>
  <div/>
</template>
<script>
export default {
  name: "tokenCheck",
  mounted() {
    this.verificationToken();
  },
  methods: {
    async verificationToken() {
      const sendData = { token: encodeURIComponent(this.$route.query.token) }

      await this.$TokenCheck('/v1/login/legacy', 'post', sendData).then((d) => {
        this.$sessionStorageUtils.save('token', JSON.stringify(d.data.body.token))
        this.$router.push({ path: '/inheritForm' })
      }).catch(error => {
        if (error.request.status === 401 || error.request.status === 403) {
          this.$router.push({ name: 'tokenInvalid' })
        } else {
          this.$router.push({ name: 'whatHappened' })
        }
      })
    }
  }
}
</script>



